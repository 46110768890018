<!-- Page "Contactez nous" -->
<template>
  <div class="contact-div">
    <!-- Titre -->
    <h1>Contactez nous</h1>
    <!-- 1ere ligne -->
    <p class="text-justify mb-0 pa-0">
      Nous donnons de l'importance à vos avis et vos réactions ! 
    </p>
    <!-- Reste du contenu texte -->
    <p class="text-justify ma-0 pa-0">  
      N'hésitez pas à nous contacter, nous serions ravis d'échanger avec vous
      sur la base de données d'occupation des sols Grand Est à Grande Echelle OCSGE2, ses usages et sur sa valorisation.
    </p>
    <p class="text-justify mt-0 pt-0">
    </p>
    <!-- Adresse de contact -->
    <div style="margin-bottom: 3%">Contactez nous à l'adresse : <a href="mailto:contact@datagrandest.fr" style="text-decoration: none">contact@datagrandest.fr</a></div>
    <v-row>
      <!-- Logo + redirection grandest -->
      <v-col>
        <v-row class="col-12" style="margin-bottom: 3%" justify="center">
          <a href="https://www.grandest.fr/" target="_blank" rel="noopener noreferrer">
            <v-img
              width="200px"
              height="150px"
              contain
              :src="require('@/assets/images/logo_grandest_blank.png')"
            ></v-img>
          </a>
        </v-row>
      </v-col>

      <!-- Logo + redirection Préfet Grand Est -->
      <v-col>
        <v-row class="col-12" style="margin-bottom: 3%" justify="center">
          <a href="https://www.prefectures-regions.gouv.fr/grand-est/" target="_blank" rel="noopener noreferrer">
            <v-img
              width="200px"
              height="150px"
              contain
              :src="require('@/assets/images/logo_prefet.png')"
            ></v-img>
          </a>
        </v-row>
      </v-col>

      <!-- Logo + redirection GeoGrandEst -->
      <v-col>
        <v-row class="col-12" style="margin-bottom: 3%" justify="center">
          <a href="https://www.geograndest.fr/portail/fr" target="_blank" rel="noopener noreferrer">
            <v-img
              width="200px"
              height="150px"
              contain
              :src="require('@/assets/images/logo_geograndest_blank.png')"
            ></v-img>
          </a>
        </v-row>
      </v-col>

      <!-- Logo + redirection KERMAP -->
      <v-col>
        <v-row class="col-12" style="margin-bottom: 3%" justify="center">
          <a href="https://kermap.com/" target="_blank" rel="noopener noreferrer">
            <v-img
              width="350px"
              height="150px"
              contain
              :src="require('@/assets/images/logo_kermap_full.jpg')"
            ></v-img>
          </a>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>
